<template>
  <div class="main">
    <div class="container">
      <van-image fit="cover" :src="src" />
    </div>

    <div class="position-box" :style="{ top: `${8 + statusHeight}px` }">
      <!-- 返回按钮 -->
      <div class="go-back" @click="close">
        <van-icon name="arrow-left" color="#fff" />
      </div>

      <!-- 右侧 -->
      <div class="box-right">
        <!-- 跳转邀请好友 -->
        <div class="go-share" @click="redEnvelopeRainShare(0)"></div>

        <!-- 右上角规则 -->
        <div class="rule" @click="ruleShow = true">规则</div>

        <!-- 右上角时间 -->
        <div class="time" @click="timeShow = true">时间</div>
      </div>
    </div>

    <div class="button" @click="gameStart">
      <span v-if="!canJoin">您已参与～</span>
      <span v-else>
        <span v-if="start">开始游戏</span>
        <span v-else>活动准备中</span>
      </span>
      <div
        style="
          margin-top: 4vw;
          font-size: 4vw;
          font-family: PingFangHK-Regular, PingFangHK;
          font-weight: 400;
          background: linear-gradient(180deg, #fff9f3 0%, #fde2c4 100%);
          -webkit-background-clip: text;
          -webkit-text-fill-color: transparent;
        "
        v-if="joined"
      >
        分享还有一次机会哦~
      </div>
    </div>

    <!-- 规则框 -->
    <van-popup v-model="ruleShow" :style="{ width: '80vw', height: '120vw' }">
      <div class="rule-box">
        <div
          style="height: calc(120vw - 90px); overflow: scroll; padding: 0 24px"
        >
          <p>1、 活动时间</p>
          <p>本活动所有时间均以北京时间为准。</p>
          <p>
            整体活动时间：2024年02月08日00：00：00--2024年02月16日23：59：59
          </p>
          <br />
          <p>2、 整体活动玩法</p>
          <p>（1）玩法一：全民抢红包瓜分年终</p>
          <p>阶段一：全民定时红包雨</p>
          <p>做任务时段：2024年02月08日00：00：00--2024年02月16日23：59：59</p>
          <p>阶段二：全民非定时红包雨</p>
          <p>做任务时段：2024年02月08日00：00：00--2024年02月16日23：59：59</p>
          <p>阶段三：转发增加红包雨次数</p>
          <p>做任务时段：2024年02月08日00：00：00--2024年02月16日23：59：59</p>
          <p>在每场活动有效时间内分享红包雨链接增加红包雨1次，逾期失效</p>
          <br />
          <p>3、 活动入口</p>
          <p>
            活动期间，用户可在Win生活App端/参与，可通过点击“红包雨”直接进入活动页面。
          </p>
        </div>
      </div>
    </van-popup>

    <!-- 时间框 -->
    <van-popup v-model="timeShow" :style="{ width: '80vw', height: '120vw' }">
      <div class="time-box">
        <div>
          <p>大年二十九2024年2月8日</p>
          <p>大年三十除夕2024年2月9日</p>
          <p>大年初一去拜年2024年2月10日</p>
          <p>大年初二回娘家2024年2月11日</p>
          <p>大年初三赤狗日2024年2月12日</p>
          <p>大年初四迎灶神2024年2月13日</p>
          <p>大年初五拜财神2024年2月14日</p>
          <p>大年初六逛庙会2024年2月15日</p>
          <p>大年初七走亲戚2024年2月16日</p>
        </div>
      </div>
    </van-popup>
  </div>
</template>

<script>
import { getRain, rainShare } from "@/api/redPackageRain";
export default {
  name: "redPackageRain",
  props: {},
  components: {},
  data() {
    return {
      // ceshi: '',//测试数据用完记得删
      id: null,
      src: require('@/assets/img/redPacketRain/redRainbg.png'),
      canJoin: true,
      showCount: false,
      start: false,
      ruleShow: false,
      timeShow: false,

      joined: false,
      timeBefore: null, // 开始前 倒计时时间
      timeBeforeDay: "00",
      timeBeforeHour: "00",
      timeBeforeMin: "00", // 开始前 倒计时时间 分
      timeBeforeSec: "00", // 开始前 倒计时时间 秒
      timerBefore: null,

      statusHeight: 20, //状态栏高度
      //
      fullWidth: document.documentElement.clientWidth,
      fullHeight: document.documentElement.clientHeight,
    };
  },
  created() {
    if (this.$route.query.id) {
      this.id = this.$route.query.id;
    }
    if (this.$route.query.session) {
      this.$store.commit("setSession", this.$route.query.session);
    }
    this.getDetail();
  },
  beforeUnmount() {},
  mounted() {
    let u = navigator.userAgent;
    let isAndroid = u.indexOf("Android") > -1 || u.indexOf("Linux") > -1; //android
    let isIOS = !!u.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/); //ios终端
    try {
      if (isIOS) {
        window.webkit.messageHandlers.setStatusBar.postMessage("1");
      }
      if (isAndroid) {
        window.android.setStatusBar("1");
      }
    } catch (error) {
      null;
    }
    let _this = this;
    if (window.outerHeight === screen.availHeight) {
      if (window.outerWidth === screen.availWidth) {
        _this.isFullScreen = false;
      }
    } else {
      _this.isFullScreen = true;
    }

    // window.onresize = () => {
    //   return (() => {
    //     window.fullHeight = document.documentElement.clientHeight;
    //     _this.fullHeight = window.fullHeight;
    //   })();
    // };

    window.statusBarHeight = this.statusBarHeight;
    window.redEnvelopeRainShareSuccess = this.redEnvelopeRainShareSuccess;
  },
  beforeDestroy() {
    clearInterval(this.timerBefore);

    let u = navigator.userAgent;
    let isAndroid = u.indexOf("Android") > -1 || u.indexOf("Linux") > -1; //android
    let isIOS = !!u.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/); //ios终端
    try {
      if (isIOS) {
        window.webkit.messageHandlers.setStatusBar.postMessage("0");
      }
      if (isAndroid) {
        window.android.setStatusBar("0");
      }
    } catch (error) {
      null;
    }
  },

  watch: {
    fullHeight() {
      //监听是否是全屏
      if (window.outerHeight === screen.availHeight) {
        if (window.outerWidth === screen.availWidth) {
          this.isFullScreen = false;
        }
      } else {
        this.isFullScreen = true;
      }
    },
  },
  methods: {
    handleResize() {
      this.fullWidth = document.documentElement.clientWidth;
      this.fullHeight = document.documentElement.clientHeight;
    },

    //
    // 分享
    redEnvelopeRainShare(type) {
      let u = navigator.userAgent;
      let isAndroid = u.indexOf("Android") > -1 || u.indexOf("Linux") > -1; //android
      let isIOS = !!u.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/); //ios终端
      let obj = {
        title: "Win生活",
        picUrl: this.src,
        content: "有一波红包雨即将来袭",
        openUrl: `#/hongbao`,
        platform: { platformId: type, isDirectShare: "false" },
      };
      if (isIOS) {
        window.webkit.messageHandlers.redEnvelopeRainShare.postMessage(
          JSON.stringify(obj)
        );
      }
      if (isAndroid) {
        window.android.redEnvelopeRainShare(JSON.stringify(obj));
      }
    },
    redEnvelopeRainShareSuccess() {
      rainShare({ redId: this.id }).then(() => {
        this.$router.go(0);
        this.getDetail();
      });
    },
    //
    close() {
      let u = navigator.userAgent;
      let isAndroid = u.indexOf("Android") > -1 || u.indexOf("Linux") > -1; //android
      let isIOS = !!u.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/); //ios终端
      if (isIOS) {
        window.webkit.messageHandlers.close.postMessage("up");
      }
      if (isAndroid) {
        window.android.close();
      }
    },
    // 获取详情
    async getDetail() {
      let res = await getRain(this.id);
      if (res.data.data.isShare == 0) {
        this.joined = true;
      } else {
        this.joined = false;
      }
      // 是否可以参加
      // this.ceshi = res//测试用
      // this.canJoin = res.data.data.status == 1 ? false : true;
      if (res.data.data.status == 0) {
        this.canJoin = true;
      } else if (res.data.data.againFlag) {
        this.canJoin = true;
      } else {
        this.canJoin = false;
      }

      if (this.canJoin) {
        // 是否显示倒计时
        this.showCount = res.data.data.countdown < 1200 ? true : false;

        // 是否可以开始
        this.start = res.data.data.countdown < 0 ? true : false;

        if (!this.start) {
          var date = new Date().getTime();
          date += res.data.data.countdown * 1000;
          this.timeBefore = date;
          this.setTimeBefore();
          this.startBeforeInterval();
        }
      }
    },
    // 每秒调用一次 开始前倒计时
    startBeforeInterval() {
      this.timerBefore = setInterval(() => {
        this.setTimeBefore();
      }, 1000);
    },
    // 计算开始前剩余时间
    setTimeBefore() {
      let now = new Date().getTime();
      let time = ((this.timeBefore - now) / 1000).toFixed(0);
      this.timeBeforeDay = Math.floor(time / 86400);
      this.timeBeforeHour = Math.floor((time % 86400) / 3600);
      this.timeBeforeMin = Math.floor((time % 3600) / 60);
      this.timeBeforeSec = time % 60;

      this.timeBeforeMin = (this.timeBeforeMin + "").padStart(2, "0"); //补零
      this.timeBeforeSec = (this.timeBeforeSec + "").padStart(2, "0"); //补零

      if (this.timeBeforeDay <= 0 && this.timeBeforeHour <= 0) {
        if (this.timeBeforeMin <= 0 && this.timeBeforeSec <= 0) {
          this.start = true;
          clearInterval(this.timerBefore);
        }
        if (this.timeBeforeMin < 15) {
          this.showCount = true;
        }
      }
    },
    gameStart() {
      // console.log(this.sesion);
      if (this.start && this.$store.state.session) {
        this.$router.replace(`redPackageRainMain?id=${this.id}`);
      }
    },
    statusBarHeight(val) {
      this.statusHeight = +val;
      // this.$dialog({ message: val });
      // alert(val);
    },
  },
};
</script>

<style lang="less" scoped>
.container {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;

  .van-image {
    width: 100%;
    height: 100%;

    /deep/ .van-image__img {
      height: 100% !important;
    }
  }
}

.main {
  width: 100%;
  height: 100%;
  overflow: hidden;

  .position-box {
    position: fixed;
    left: 0;
    width: 100%;
    display: flex;
    justify-content: space-between;

    .go-back {
      margin-top: 4px;
      margin-left: 16px;
      font-size: 22px;
    }

    .box-right {
      .go-share {
        width: 50px;
        height: 49px;
        background-image: url("../../assets/img/redPacketRain/redShare.png");
        background-repeat: no-repeat;
        background-size: 100%;
      }

      .rule,
      .time {
        color: #fff;
        width: 50px;
        height: 36px;
        background-color: #ff4d44;
        border-radius: 18px 0 0 18px;
        display: flex;
        justify-content: center;
        align-items: center;
      }

      .rule {
        top: 66px;
        margin-top: 17px;
      }

      .time {
        margin-top: 12px;
        top: 108px;
      }
    }
  }

  .button {
    position: fixed;
    top: 77vh;
    left: 50%;
    transform: translateX(-50%);
    width: 72.6vw;
    height: 11vh;
    background-image: url("../../assets/img/redPacketRain/button1.png");
    background-size: 100%;
    text-align: center;
    font-size: 6vw;
    color: #fff;
    box-sizing: border-box;
    padding-top: 2.2vh;
  }

  .rule-box,
  .time-box {
    background-repeat: no-repeat;
    background-size: 100% 100%;
    height: 120vw;
    box-sizing: border-box;
  }

  .rule-box {
    background-image: url("../../assets/img/redPacketRain/redRule.png");
    padding: 50px 0 40px;
    color: #ab6947;
  }

  .time-box {
    background-image: url("../../assets/img/redPacketRain/redTime.png");
    text-align: center;
    color: #ec4c23;
    display: flex;
    justify-content: center;
    line-height: 4vh;
    align-items: center;
  }
}

/deep/.van-popup--center {
  background-color: transparent;
}
</style>
